import React from 'react';
import ChartPie from '../../assets/img/service/chart-pie.svg';
import Microphone from '../../assets/img/service/microphone.svg';
import Money from '../../assets/img/service/money.svg';
import Seo from '../../assets/img/service/seo.svg';
import Protection from '../../assets/img/service/protection.svg';
import Users from '../../assets/img/service/users.svg';
import './Service.scss';

export const Service = () => {
  return (
    <section className='Service' id='service'>
      <div className='Container'>
        <header className='Service__Header'>
          <h2 className='Service__Title'>Сервис</h2>
          <h3 className='Service__Subtitle'>
            Все услуги предоставляются в соответствии с законодательством
            Российской Федерации
          </h3>
        </header>

        <div className='Service__Row'>
          <div className='Card'>
            <img className='Card__Img' src={Money} alt='icon' />
            <h4 className='Card__Title'>CRM-система</h4>
            <p className='Card__Text'>
              Предлагаем Вам воспользоваться обновлённой, удобной CRM-системой
              для ведения своей клиентской базы
            </p>
          </div>

          <div className='Card'>
            <img className='Card__Img' src={Microphone} alt='icon' />
            <h4 className='Card__Title'>Обратная связь</h4>
            <p className='Card__Text'>
              Установите на свой сайт стильную форму обратной связи, заявки из
              которой будут обработаны сервисом
            </p>
          </div>

          <div className='Card'>
            <img className='Card__Img' src={ChartPie} alt='icon' />
            <h4 className='Card__Title'>Сиситема захвата Лидов</h4>
            <p className='Card__Text'>
              Воспользуйтесь возможностью введения Лидо-генерации для Вашего
              сайта с помощью нашей системы
            </p>
          </div>

          <div className='Card'>
            <img className='Card__Img' src={Seo} alt='icon' />
            <h4 className='Card__Title'>SEO продвижение</h4>
            <p className='Card__Text'>
              Анализ позиций сайт, рекомендации по продвижению, обмен внешними
              ссылками со сторонними сайтами
            </p>
          </div>

          <div className='Card'>
            <img className='Card__Img' src={Protection} alt='icon' />
            <h4 className='Card__Title'>Защита сайта</h4>
            <p className='Card__Text'>
              Установите на свой сайт защищающий код нашей системы, которая
              уведомит Вас о несанкционированных действиях
            </p>
          </div>

          <div className='Card'>
            <img className='Card__Img' src={Users} alt='icon' />
            <h4 className='Card__Title'>Трекер</h4>
            <p className='Card__Text'>
              Получайте пользователей в социальных сетях для увеличения
              конверсии
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
