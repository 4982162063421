import React from 'react';
import { Button } from '../../ui-kit/Button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import './Navbar.scss';

export const Navbar = () => {
  const navigate = useNavigate();

  return (
    <nav className='Navbar'>
      <div className='Container'>
        <div className='Navbar-Row'>
          <strong className='Logo'>rasalink</strong>
          <ul className='NavList'>
            <li className='NavList__Item'>
              <a href='#start'>Старт</a>
            </li>
            <li className='NavList__Item'>
              <a href='#about'>О нас</a>
            </li>
            <li className='NavList__Item'>
              <a href='#service'>Сервис</a>
            </li>
            <li className='NavList__Item'>
              <a href='#clients'>Отзывы</a>
            </li>
            <li className='NavList__Item'>
              <a href='#price'>Цены</a>
            </li>
          </ul>
          <div className='ButtonWrapper'>
            <Button onClick={() => navigate(ROUTES.LOGIN)}>Вход</Button>
          </div>
        </div>
      </div>
    </nav>
  );
};
