import React from 'react';
import './Footer.scss';

export const Footer = () => {
  return (
    <div className='Footer'>
      <div className='Container'>
        <div className='Footer__Copyright'>
          <span>Копирайт &copy; 2023</span>
        </div>
      </div>
    </div>
  );
};
