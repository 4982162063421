import React, { FC } from 'react';
import User from '../../assets/img/users/01.png';
import './SliderItem.scss';

interface ISliderItem {
  title?: string;
  name?: string;
  position?: string;
  email?: string;
  img?: string;
}

export const SliderItem: FC<ISliderItem> = (props) => {
  const { title, name, position, email, img } = props;

  return (
    <div className='SliderItem'>
      <div className='SliderItem__Text'>
        <p>“{title}”</p>
      </div>
      <div className='SliderItem__Author'>
        <div className='SliderItem__Avatar'>
          <img src={img} alt='Avatar' />
        </div>
        <div className='SliderItem__Author-Desc'>
          <span className='SliderItem__Author-Title'>{position}</span>
          <span className='SliderItem__Author-Name'>{name}</span>
          <span className='SliderItem__Author-Email'>{email}</span>
        </div>
      </div>
    </div>
  );
};
