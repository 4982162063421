import React from 'react';
import './StartUsing.scss';
import { Button } from '../../ui-kit/Button/Button';

export const StartUsing = () => {
  return (
    <section className='StartUsing' id='start'>
      <div className='Container'>
        <div className='StartUsing__Content'>
          <h2 className='StartUsing__Title'>
            После того как Вы пройдёте проверку, у Вас будет возможность
            полностью получить своих потенциальных клиентов в социальных сетях.
          </h2>
          <div className='StartUsing__Button'>
            <Button>Регистрация</Button>
          </div>
        </div>
      </div>
    </section>
  );
};
