import React from 'react';
import './Price.scss';
import { Button } from '../../ui-kit/Button/Button';

export const Price = () => {
  return (
    <section className='Price' id='price'>
      <div className='Container'>
        <header className='Price__Header'>
          <h2 className='Price__Title'>
            <span>Цены</span>
          </h2>
          <h3 className='Price__Subtitle'>
            Так же наш сервис предоставляет дополнительные технические и
            консультационные услуги
          </h3>
        </header>

        <div className='Price__Row'>
          <div className='Package'>
            <div className='Package__Header'>
              <h4 className='Package__Title'>стартовая</h4>
              <span className='Package__Price'>1450</span>
              <span className='Package__Period'>&#8381;/мес</span>
              <span className='Package__Subtitle'>
                Доступен базовый функционал системы
              </span>
            </div>
            <ul className='Package__List'>
              <li className='Package__List-Item'>Ведение клиентской базы</li>
              <li className='Package__List-Item'>Работа с Лидами</li>
              <li className='Package__List-Item'>Супер тИЦ</li>
              <li className='Package__List-Item'>Форма обратной связи</li>
              <li className='Package__List-Item'>SEO сканер</li>
              <li className='Package__List-Item'>Защита сайта</li>
              <li className='Package__List-Item'>Смс уведомления</li>
              <li className='Package__List-Item'>
                Система отчётов для Ваших клиентов
              </li>
              <li className='Package__List-Item'>
                Трекер (до 1000 Лидов в месяц)
              </li>
            </ul>
            <div className='Package__Button'>
              <Button>Начать пользоваться</Button>
            </div>
          </div>

          <div className='Package Package--Special'>
            <div className='Package__Header'>
              <h4 className='Package__Title'>Расширенная</h4>
              <span className='Package__Price'>4450</span>
              <span className='Package__Period'>&#8381;/мес</span>
              <span className='Package__Subtitle'>
                Доступен расширенный функционал системы
              </span>
            </div>
            <ul className='Package__List'>
              <li className='Package__List-Item'>Ведение клиентской базы</li>
              <li className='Package__List-Item'>Работа с Лидами</li>
              <li className='Package__List-Item'>Супер тИЦ</li>
              <li className='Package__List-Item'>До 10 Форм обратной связи</li>
              <li className='Package__List-Item'>SEO сканер</li>
              <li className='Package__List-Item'>Защита сайта</li>
              <li className='Package__List-Item'>Смс уведомления</li>
              <li className='Package__List-Item'>
                Срочная техническая поддержка
              </li>
              <li className='Package__List-Item'>
                Система отчётов для Ваших клиентов
              </li>
              <li className='Package__List-Item'>
                Трекер (до 1000 Лидов в месяц)
              </li>
            </ul>
            <div className='Package__Button'>
              <Button>Начать пользоваться</Button>
            </div>
          </div>

          <div className='Package'>
            <div className='Package__Header'>
              <h4 className='Package__Title'>Максимальная</h4>
              <span className='Package__Price'>7450</span>
              <span className='Package__Period'>&#8381;/мес</span>
              <span className='Package__Subtitle'>
                Базовая поддержка клиентов для малого бизнеса
              </span>
            </div>
            <ul className='Package__List'>
              <li className='Package__List-Item'>Ведение клиентской базы</li>
              <li className='Package__List-Item'>Работа с Лидами</li>
              <li className='Package__List-Item'>Супер тИЦ</li>
              <li className='Package__List-Item'>До 50 Форм обратной связи</li>
              <li className='Package__List-Item'>SEO сканер</li>
              <li className='Package__List-Item'>Защита сайта</li>
              <li className='Package__List-Item'>Смс уведомления</li>
              <li className='Package__List-Item'>
                Срочная техническая поддержка
              </li>
              <li className='Package__List-Item'>
                Система отчётов для Ваших клиентов
              </li>
              <li className='Package__List-Item'>
                Трекер (до 1000 Лидов в месяц)
              </li>
            </ul>
            <div className='Package__Button'>
              <Button>Начать пользоваться</Button>
            </div>
          </div>

          <div className='Package Package--Special'>
            <div className='Package__Header'>
              <h4 className='Package__Title'>Безлимитная</h4>
              <span className='Package__Price'>14450</span>
              <span className='Package__Period'>&#8381;/мес</span>
              <span className='Package__Subtitle'>
                Базовая поддержка клиентов для малого бизнеса
              </span>
            </div>
            <ul className='Package__List'>
              <li className='Package__List-Item'>Ведение клиентской базы</li>
              <li className='Package__List-Item'>Работа с Лидами</li>
              <li className='Package__List-Item'>Супер тИЦ</li>
              <li className='Package__List-Item'>До 50 Форм обратной связи</li>
              <li className='Package__List-Item'>SEO сканер</li>
              <li className='Package__List-Item'>Защита сайта</li>
              <li className='Package__List-Item'>Смс уведомления</li>
              <li className='Package__List-Item'>
                Срочная техническая поддержка
              </li>
              <li className='Package__List-Item'>
                Система отчётов для Ваших клиентов
              </li>
              <li className='Package__List-Item'>
                Трекер (до 1000000 Лидов в месяц)
              </li>
            </ul>
            <div className='Package__Button'>
              <Button>Начать пользоваться</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
