import React from 'react';
import { Navbar } from '../../components/Navbar/Navbar';
import { Heading } from '../../sections/Heading/Heading';
import { About } from '../../sections/About/About';
import { StartUsing } from '../../sections/StartUsing/StartUsing';
import { Service } from '../../sections/Service/Service';
import { Clients } from '../../sections/Clients/Clients';
import { Price } from '../../sections/Price/Price';
import './MainPage.scss';
import { Footer } from '../../components/Footer/Footer';

export const MainPage = () => {
  return (
    <>
      <Navbar />
      <Heading />
      <About />
      <StartUsing />
      <Service />
      <Price />
      <Clients />
      <Footer />
    </>
  );
};
