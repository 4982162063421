import React from 'react';
import './About.scss';

export const About = () => {
  return (
    <section className='About' id='about'>
      <div className='Container'>
        <header className='About__Header'>
          <h2 className='About__Title'>Как работает наша система?</h2>
          <h3 className='About__Subtitle'>
            Наша система rasa.link не является спам сервисом, мы крайне серьёзно
            и уважительно относимся к личному пространству человека в сети.
            Поэтому перед началом использования системы каждый абонент проходит
            модерацию.
          </h3>
        </header>
      </div>
    </section>
  );
};
