import React from 'react';
import './Heading.scss';

export const Heading = () => {
  return (
    <div className='Heading' id='home'>
      <div className='Heading__Content'>
        <h1 className='Heading__Title'>rasalink</h1>
        <p className='Heading__Subtitle'>
          это удобное решение для Вашего бизнеса
        </p>
      </div>
    </div>
  );
};
