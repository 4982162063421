import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  memo,
} from 'react';
import classNames from 'classnames';
import './Button.scss';

export interface IButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  className?: string;
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

const ButtonComponent: FC<IButtonProps> = (props) => {
  const { className, children, isDisabled = false, onClick, ...rest } = props;
  return (
    <button
      className={classNames('Button', className)}
      disabled={isDisabled}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};
export const Button = memo(ButtonComponent);
