import React, {
  ChangeEvent,
  DetailedHTMLProps,
  InputHTMLAttributes,
  KeyboardEvent,
} from 'react';
import classNames from 'classnames';
import './TextField.scss';

type DefaultInputPropsType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type InputFormProps = DefaultInputPropsType & {
  onChangeText?: (value: string) => void;
  onEnter?: () => void;
  error?: string;
  spanClassName?: string;
  label?: string;
};

export const TextField: React.FC<InputFormProps> = (props) => {
  const {
    type,
    onChange,
    onChangeText,
    onKeyDown,
    onEnter,
    error,
    className,
    spanClassName,
    label,
    ...rest
  } = props;

  const onChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
    onChangeText && onChangeText(e.currentTarget.value);
  };
  const onKeyPressCallback = (e: KeyboardEvent<HTMLInputElement>) => {
    onKeyDown && onKeyDown(e);

    onEnter && e.key === 'Enter' && onEnter();
  };

  return (
    <div className={classNames('TextField', className)}>
      {label && <label className='TextField__Label'>{label}</label>}
      <input
        type={type}
        onChange={onChangeCallback}
        onKeyDown={onKeyPressCallback}
        className='TextField__Input'
        style={{ border: `1px solid ${error ? '#e64646' : '#e1e1e1'}` }}
        {...rest}
      />
      {error && <span className='TextField__Error'>{error}</span>}
    </div>
  );
};
