import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { MainPage } from './pages/MainPage/MainPage';
import { ROUTES } from './routes';
import './App.scss';

export const App = () => {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={ROUTES.MAIN} element={<MainPage />} />
          <Route path={'*'} element={<MainPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
