import React from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import User from '../../assets/img/users/01.png';
import User2 from '../../assets/img/users/02.jpg';
import User3 from '../../assets/img/users/03.jpg';
import ArrowLeft from '../../assets/img/slider/arrow-left.svg';
import ArrowRight from '../../assets/img/slider/arrow-right.svg';
import { SliderItem } from '../../components/SliderItem/SliderItem';
import './Clients.scss';

export const Clients = () => {
  return (
    <section className='Clients' id='clients'>
      <div className='Container'>
        <header className='Clients__Header'>
          <h2 className='Clients__Title'>Что говорят пользователи?</h2>
          <h3 className='Clients__Subtitle'>
            Почитайте краткие отзывы людей которые уже пользуются нашей
            системой.
          </h3>
        </header>
        <div className='SliderWrapper'>
          <div className='Carousel'>
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={30}
              totalSlides={3}
              infinite={true}
            >
              <Slider>
                <Slide index={0}>
                  <SliderItem
                    img={User2}
                    title={
                      'Подключила руттрекер к своему одностраничному сайту. Продажи пошли в первый день - через час после подключения. Благодарю ваш сайт, молодцы создатели.'
                    }
                    position={'Директор'}
                    name={'Анастасия '}
                    email={'15mam.ru'}
                  />
                </Slide>
                <Slide index={1}>
                  <SliderItem
                    img={User}
                    title={
                      'Зарегистрировался на rasa.link Не ожидал такого мощного функционала, конечно сам КД был бы несерьёзен без возможность вести лидогенерацию и работать с клиентами.'
                    }
                    position={'Директор'}
                    name={'Кирилл'}
                    email={'zao-tehnolog.ru'}
                  />
                </Slide>
                <Slide index={2}>
                  <SliderItem
                    img={User3}
                    title={
                      'Очень удобно работать с сотрудниками и вести отчёты. Выражаем благодарность коллективу, ребята вы молодцы! Продажи увеличились в разы!'
                    }
                    position={'Директор'}
                    name={'Ольга'}
                    email={'vermont-ekb.ru'}
                  />
                </Slide>
              </Slider>
              <ButtonBack className='CarouselButton CarouselButtonBack'>
                <img src={ArrowLeft} alt='arrow-left' />
              </ButtonBack>
              <ButtonNext className='CarouselButton CarouselButtonNext'>
                <img src={ArrowRight} alt='arrow-right' />
              </ButtonNext>
              <DotGroup className='CarouselDotGroup' />
            </CarouselProvider>
          </div>
        </div>
      </div>
    </section>
  );
};
