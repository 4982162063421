import React, { FC, memo } from 'react';
import classNames from 'classnames';
import './Checkbox.scss';

interface ICheckboxProps {
  className?: string;
  label?: string;
  isChecked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxComponent: FC<ICheckboxProps> = (props) => {
  const { className, label, isChecked, onChange } = props;

  return (
    <label className={classNames('Checkbox', className)}>
      <input
        type='checkbox'
        className='RealCheckbox'
        checked={isChecked}
        name={label}
        value={label}
        onChange={onChange}
      />
      <span className='CustomCheckbox'></span>
      {label && <span className='Label'>{label}</span>}
    </label>
  );
};

export const Checkbox = memo(CheckboxComponent);
