import React from 'react';
import { Button } from '../../ui-kit/Button/Button';
import './LoginPage.scss';
import { TextField } from '../../ui-kit/TextField/TextField';
import { Checkbox } from '../../ui-kit/Checkbox/Checkbox';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';

export const LoginPage = () => {
  const navigate = useNavigate();

  return (
    <div className='LoginPage'>
      <div className='LoginPage__Content'>
        <form className='LoginPage__Form'>
          <h3 className='LoginPage__Title'>Введите данные</h3>
          <div className='FormGroup'>
            <div className='FormGroup__Row'>
              <TextField placeholder='Введите имя' />
            </div>
            <div className='FormGroup__Row'>
              <TextField placeholder='Email' />
            </div>
            <div className='FormGroup__Row'>
              <TextField placeholder='Телефон' />
              <Checkbox className='CheckboxStyle' label='Запомнить Вас' />
            </div>
          </div>
          <div className='LoginPage__Button'>
            <Button
              className='ButtonStyle ButtonСancel'
              onClick={() => navigate(ROUTES.MAIN)}
            >
              Отмена
            </Button>
            <Button className='ButtonStyle'>Войти</Button>
          </div>
        </form>
      </div>
    </div>
  );
};
